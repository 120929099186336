import { BaseModal } from "./common/BaseModal";
import { AiOutlineClose, AiOutlineWarning } from "react-icons/ai";
import { PrimaryButton } from "./common/Button";
import { toDateString } from "../utils/date";
import { useState } from "react";
import { FiLoader } from "react-icons/fi";

interface CancelSubscriptionModalProps {
  currentPeriodEnd: string;
  isOpen: boolean;
  onRequestClose: () => void;
  onOK: () => Promise<void>;
}

export const CancelSubscriptionModal: React.FC<
  CancelSubscriptionModalProps
> = ({ currentPeriodEnd, isOpen, onRequestClose, onOK }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      height={430}
      className="w-full relative bg-gradient-to-br from-red-50 to-orange-50 p-8 rounded-lg shadow-lg"
    >
      <div>
        <button
          className="absolute top-2 right-2 cursor-pointer transition-transform duration-300 hover:scale-110"
          onClick={onRequestClose}
          aria-label="Close modal"
        >
          <AiOutlineClose className="w-6 h-6 text-red-600" />
        </button>
        <div className="flex flex-col items-center">
          <AiOutlineWarning className="w-16 h-16 text-orange-500 mb-4" />
          <h2 className="text-2xl font-bold text-red-700 mb-4">
            プラン解約の確認
          </h2>
          <p className="text-gray-700 text-center mb-6">
            プランの自動更新を停止します。
            <br />
            <span className="font-semibold">
              {toDateString(currentPeriodEnd)}まで
            </span>
            は、ベーシックプランをご利用いただけます。
          </p>
          <PrimaryButton
            onClick={async () => {
              setIsLoading(true);
              await onOK();
              setIsLoading(false);
              onRequestClose();
            }}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105"
          >
            {isLoading ? (
              <FiLoader className="animate-spin" />
            ) : (
              "プランの自動更新を停止する"
            )}
          </PrimaryButton>
        </div>
      </div>
    </BaseModal>
  );
};
