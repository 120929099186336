import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown, Menu } from "lucide-react";
import { useSupabase } from "../../hooks/supabase/use-supabase";
import { useCallback } from "react";
import { ModeToggle } from "../mode-toggle";

export function Header({
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}) {
  const { supabase, user } = useSupabase();

  const onSignOutClicked = useCallback(async () => {
    if (supabase) {
      await supabase.auth.signOut();

      location.reload();
    }
  }, [supabase]);

  return (
    <header className="shadow-sm p-4 flex lg:justify-end justify-between items-center border-b">
      <Button
        variant="ghost"
        size="icon"
        className="lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <Menu className="h-6 w-6" />
      </Button>
      <div className="flex items-center space-x-2">
        <ModeToggle />

        <div className="flex items-center">
          <span className="mr-2">{user?.email}</span>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <ChevronDown className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={onSignOutClicked}>
                ログアウト
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
}
