import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApps } from "../hooks/use-apps";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoadingIndicator } from "./common/LoadingIndicator";

enum CreateError {
  SERVER_ERROR,
  APP_LIMIT,
}

function getErrorMessage(error: CreateError) {
  switch (error) {
    case CreateError.SERVER_ERROR:
      return "エラーが発生しました。再度お試しください。";
    case CreateError.APP_LIMIT:
      return "アプリの作成上限に達しました。";
  }
}

export const CreateForm = ({ defaultName }: { defaultName: string }) => {
  const { apps, createApp } = useApps();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState(defaultName);
  const [isError, setIsError] = useState<CreateError | null>(null);

  const onCreate = useCallback(async () => {
    if (apps.data && apps.data?.length >= 5) {
      setIsError(CreateError.APP_LIMIT);
      return;
    }

    setIsLoading(true);

    try {
      const appId = await createApp(name);
      setIsLoading(false);

      if (appId) {
        navigate(`apps/${appId}/start`);
      } else {
        setIsError(CreateError.SERVER_ERROR);
      }
    } catch (e) {
      setIsError(CreateError.SERVER_ERROR);
      setIsLoading(false);
    }
  }, [createApp, name, navigate]);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">新しいアプリを作成</h2>
      <Card>
        <CardHeader>
          <CardTitle>アプリ情報</CardTitle>
          <CardDescription>
            新しいアプリの詳細を入力してください。
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4 sm:grid-cols-2">
            <div className="flex flex-col space-y-1.5 sm:col-span-2">
              <Label htmlFor="name">アプリ名</Label>
              <Input
                id="name"
                placeholder="アプリ名を入力"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="text-red text-xs">
            {isError ? getErrorMessage(isError) : null}
          </div>

          <Button variant="default" className="mt-4" onClick={onCreate}>
            {isLoading ? <LoadingIndicator /> : "アプリを作る"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};
