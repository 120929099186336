import { useRule } from "../../hooks/use-rule";
import { TextInput } from "../../components/common/TextInput";
import { Clipboard } from "../../components/common/Clipboard";
import { PrimaryButton } from "../../components/common/Button";
import { useState } from "react";
import { UpdateKeyModal } from "../UpdateKeyModal";
import { Card } from "../ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";

type LoginRestriction = "no_restriction" | "registered_only" | "logged_in_only";

type Restriction =
  | "no_restriction"
  | "registered_only"
  | "logged_in_only"
  | "logged_in_only_jwk";

function toRestriction(
  loginRestriction: LoginRestriction,
  isOIDCEnabled: boolean
): Restriction {
  if (loginRestriction === "no_restriction") {
    return "no_restriction";
  } else if (loginRestriction === "registered_only") {
    return "registered_only";
  } else if (loginRestriction === "logged_in_only") {
    if (isOIDCEnabled) {
      return "logged_in_only";
    } else {
      return "logged_in_only_jwk";
    }
  }

  return "no_restriction";
}

export function RuleForm({
  ruleId,
  isOidcdEnabled,
  publicKey,
  defaultRestriction,
}: {
  ruleId: number;
  isOidcdEnabled: boolean;
  publicKey: string;
  defaultRestriction: LoginRestriction;
}) {
  const [restriction, setRestriction] = useState<Restriction>(
    toRestriction(defaultRestriction, isOidcdEnabled)
  );

  return (
    <Card className="p-8 rounded-lg shadow space-y-4">
      <div className="text-base font-bold">APIアクセス制限</div>
      <RuleRestrictionSelector
        onSelect={setRestriction}
        restriction={restriction}
      />
      <RuleContentView
        ruleId={ruleId}
        publicKey={publicKey}
        restriction={restriction}
      />
    </Card>
  );
}

function RuleContentView({
  ruleId,
  publicKey,
  restriction,
}: {
  ruleId: number;
  publicKey: string;
  restriction: Restriction;
}) {
  if (restriction === "no_restriction") {
    return <AppRuleNoRestriction ruleId={ruleId} />;
  } else if (restriction === "registered_only") {
    return <AppRuleReCapture ruleId={ruleId} defaultSecret={""} />;
  } else if (restriction === "logged_in_only") {
    return (
      <AppAuth0Form ruleId={ruleId} defaultIssuer={""} defaultAudience={""} />
    );
  } else if (restriction === "logged_in_only_jwk") {
    return <AppAuthWithJWKForm ruleId={ruleId} defaultPublicKey={publicKey} />;
  }
}

function RuleRestrictionSelector({
  restriction,
  onSelect,
}: {
  restriction: Restriction;
  onSelect: (restriction: Restriction) => void;
}) {
  return (
    <Select
      defaultValue={restriction}
      onValueChange={(value) => onSelect(value as Restriction)}
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={"no_restriction"}>制限なし</SelectItem>
        <SelectItem value={"logged_in_only_jwk"}>API認証あり</SelectItem>
      </SelectContent>
    </Select>
  );
}

function AppRuleNoRestriction({ ruleId }: { ruleId: number }) {
  const { rule, setNoRestriction, regenerateKey } = useRule(ruleId);
  const [isUpdateKeyModalOpen, setIsUpdateKeyModalOpen] = useState(false);
  if (!rule.data) {
    return <div>Loading...</div>;
  }

  const isDisabled = rule.data.rule.login_restriction === "no_restriction";

  return (
    <div className="space-y-4">
      <div className="space-y-4">
        <div className="text-base font-bold">公開可能キー</div>

        <div className="flex items-center space-x-1">
          <TextInput value={rule.data.rule.public_sig || ""} isDisabled />
          <Clipboard value={rule.data.rule.public_sig || ""} />
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <div>
          <Button
            variant={"outline"}
            onClick={() => setIsUpdateKeyModalOpen(true)}
          >
            再生成する
          </Button>
        </div>

        <div>
          <Button
            variant={"default"}
            onClick={() => setNoRestriction()}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </div>

      <UpdateKeyModal
        isOpen={isUpdateKeyModalOpen}
        onRequestClose={() => setIsUpdateKeyModalOpen(false)}
        onRegenerate={async () => {
          await regenerateKey();
          await setNoRestriction();
          setIsUpdateKeyModalOpen(false);
        }}
      />
    </div>
  );
}

function AppRuleReCapture({
  ruleId,
  defaultSecret,
}: {
  ruleId: number;
  defaultSecret: string;
}) {
  const { rule, setRecapture } = useRule(ruleId);

  const [secret, setSecret] = useState(defaultSecret);

  if (!rule.data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-3">
      <div className="space-y-4">
        <div className="text-base font-bold">公開可能キー</div>

        <div className="flex items-center space-x-1">
          <TextInput value={rule.data.rule.public_sig || ""} isDisabled />
          <Clipboard value={rule.data.rule.public_sig || ""} />
        </div>
      </div>

      <div className="space-y-4">
        <div className="text-base font-bold">ReCapture secret</div>
        <div className="flex items-center space-x-1">
          <TextInput value={secret} onChange={setSecret} />
        </div>
      </div>

      <div className="flex justify-end">
        <div className="w-[180px]">
          <PrimaryButton onClick={() => setRecapture(secret)}>
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

function AppAuth0Form({
  ruleId,
  defaultIssuer,
  defaultAudience,
}: {
  ruleId: number;
  defaultIssuer: string;
  defaultAudience: string;
}) {
  const { setOIDC } = useRule(ruleId);

  const [issuer, setIssuer] = useState(defaultIssuer);
  const [audience, setAudience] = useState(defaultAudience);

  return (
    <div className="space-y-3">
      <div className="text-base font-bold">Issuer</div>
      <div className="flex items-center space-x-1">
        <TextInput value={issuer} onChange={setIssuer} />
      </div>
      <div className="text-base font-bold">Client ID</div>
      <div className="flex items-center space-x-1">
        <TextInput value={audience} onChange={setAudience} />
      </div>
      <PrimaryButton onClick={() => setOIDC(issuer, audience)}>
        Save
      </PrimaryButton>
    </div>
  );
}

function AppAuthWithJWKForm({
  ruleId,
  defaultPublicKey,
}: {
  ruleId: number;
  defaultPublicKey: string;
}) {
  const { setJWKForAuth } = useRule(ruleId);

  const [publicKey, setPublicKey] = useState(defaultPublicKey);

  return (
    <div className="space-y-3">
      <div className="text-base font-bold">JWK</div>
      <div className="flex items-center space-x-1">
        <TextInput value={publicKey} onChange={setPublicKey} />
      </div>
      <PrimaryButton onClick={() => setJWKForAuth(publicKey)}>
        Save
      </PrimaryButton>
    </div>
  );
}
