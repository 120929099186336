import { BaseModal } from "./common/BaseModal";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "./ui/button";

export const UpdateKeyModal = ({
  isOpen,
  onRequestClose,
  onRegenerate,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  onRegenerate: () => void;
}) => {
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="absolute top-2 right-2 cursor-pointer">
        <AiOutlineClose
          className="w-8 h-8 text-zinc-800"
          onClick={onRequestClose}
        />
      </div>
      <div className="mt-10 flex justify-center items-center">
        <div className="space-y-5">
          <div className="text-muted-foreground text-sm font-bold">
            キーを再生成すると、公開可能キーとシークレットキーの両方が新しくなります。前の公開可能キーでは、APIにアクセスできなくなります。
          </div>
          <div className="flex justify-center items-center">
            <Button variant={"default"} onClick={onRegenerate}>
              再生成する
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
