import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { Title } from "../../components/common/Title";
import { useUsages } from "../../hooks/use-usages";
import { toDateString } from "../../utils/date";
import { useCheckoutSession } from "../../hooks/use-checkout-session";
import { FiPlus, FiCreditCard, FiList, FiLoader } from "react-icons/fi";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { CreditChart } from "../../components/usage/CreditChart";
export function AppUsages() {
  const { appid } = useParams();
  const appId = Number(appid);
  const { app } = useApp(appId);
  const { usages, wallets, credit, credit_logs } = useUsages(appId);
  const { getAddCreditSession } = useCheckoutSession(appId.toString());
  const [isLoading, setIsLoading] = useState(false);

  const addCredit = async () => {
    setIsLoading(true);
    const session = await getAddCreditSession();
    if (session) {
      window.location.href = session.url;
    }
    setIsLoading(false);
  };

  if (!app.data || !usages.data || !wallets.data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Title className="text-3xl font-bold mb-8">アプリ利用状況</Title>

      <Card>
        <CardHeader>
          <CardTitle>今月の利用量</CardTitle>
        </CardHeader>
        <CardContent>
          <UsageGrid>
            <UsageGridItem
              title="ウォレット作成数"
              value={usages.data.create_account.count_credit_used}
            />
            <UsageGridItem
              title="送付数"
              value={usages.data.transfer.count_credit_used}
            />
            <UsageGridItem
              title="リンク送付数"
              value={usages.data.link_transfer_submit.count_credit_used}
            />
            <UsageGridItem
              title="受け取り数"
              value={usages.data.link_transfer_receive.count_credit_used}
            />
          </UsageGrid>
        </CardContent>
      </Card>

      {credit.data && (
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <FiCreditCard className="mr-2" /> クレジット
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex justify-between items-center">
              <div className="text-3xl font-bold text-primary">
                {credit.data?.quantity} クレジット
              </div>
              <Button
                variant="destructive"
                onClick={() => addCredit()}
                className="flex items-center"
              >
                {isLoading ? (
                  <FiLoader className="mr-2 animate-spin" />
                ) : (
                  <FiPlus className="mr-2" />
                )}
                100クレジットを追加
              </Button>
            </div>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <FiList className="mr-2" /> クレジットログ
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div>
            {credit_logs.data && credit_logs.data.length > 0 ? (
              <CreditChart data={credit_logs.data} />
            ) : (
              "Empty Data"
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function UsageGrid({ children }: { children: React.ReactNode }) {
  return <div className="grid grid-cols-2 gap-6">{children}</div>;
}

function UsageGridItem({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <div className="rounded-lg p-4 shadow-sm">
      <div className="text-sm font-medium text-muted-foreground mb-2">
        {title}
      </div>
      <div className="text-2xl font-bold text-foreground">{value}</div>
    </div>
  );
}

export function CreditLogsTable({
  data,
}: {
  data: {
    id: number;
    direction: string;
    quantity: number | null;
    day: string;
  }[];
}) {
  console.log(data);
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">操作</TableHead>
          <TableHead>数量</TableHead>
          <TableHead>日時</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data ? (
          data.map((log) => (
            <TableRow key={log.id}>
              <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                {log.direction === "increment" ? "追加" : "消費"}
              </TableCell>
              <TableCell className="px-6 py-4 whitespace-nowrap text-sm">
                {log.quantity ?? "-"}
              </TableCell>
              <TableCell className="px-6 py-4 whitespace-nowrap text-sm">
                {toDateString(log.day)}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={3}
              className="px-6 py-4 whitespace-nowrap text-sm text-center"
            >
              ログがありません
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
