import { StripeSubscription } from "../../types";
import { toDateString } from "../../utils/date";

export function AutoRenewalScheduledToEnd({
  subscription,
}: {
  subscription: StripeSubscription;
}) {
  if (!subscription.cancel_at_period_end || !subscription.current_period_end) {
    return null;
  }

  return (
    <div className="flex justify-center mt-8">
      {subscription.cancel_at_period_end && subscription.current_period_end && (
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
          <div className="text-center">
            <span className="font-bold text-xl">自動更新終了予定</span>
            <p className="mt-2">
              <span className="font-semibold">
                {toDateString(subscription.current_period_end)}
              </span>{" "}
              まで
            </p>
            <p className="mt-1">ベーシックプランをお楽しみいただけます</p>
          </div>
        </div>
      )}
    </div>
  );
}
